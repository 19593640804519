import * as React from "react"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import StarIcon from "@material-ui/icons/StarBorder"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { makeStyles, Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { Auth } from "aws-amplify"
import { navigate } from "gatsby"
import SignIn from "../signin_up/SignIn"
import { Modal, Fade, Backdrop } from "@material-ui/core"

const styles = (theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white
      },
      ul: {
        margin: 0,
        padding: 0
      },
      li: {
        listStyle: "none"
      }
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    toolbar: {
      flexWrap: "wrap"
    },
    toolbarTitle: {
      flexGrow: 1
    },
    link: {
      margin: theme.spacing(1, 1.5)
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6)
    },
    cardHeader: {
      backgroundColor: theme.palette.grey[200]
    },
    cardPricing: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      marginBottom: theme.spacing(2)
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6)
      }
    },
    card: {
      minWidth: "300px"
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    signInButtons: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    starupSpecial: {
      fontSize: "2rem"
    }
  })

const tiers = [
  // {
  //   title: "Free",
  //   price: "0",
  //   description: ["10 users included", "2 GB of storage", "Help center access", "Email support"],
  //   buttonText: "Sign up for free",
  //   buttonVariant: "outlined"
  // },
  {
    title: "ESP-IDF",
    subheader: "The Official ESP32 framework",
    price: "75",
    description: ["Once-off Payment = Lifetime Access", "Risk free", "14 Day Refund Policy"],
    buttonText: "Get started",
    buttonVariant: "contained"
  }
  // {
  //   title: "Enterprise",
  //   price: "30",
  //   description: ["50 users included", "30 GB of storage", "Help center access", "Phone & email support"],
  //   buttonText: "Contact us",
  //   buttonVariant: "outlined"
  // }
]
const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"]
  },
  {
    title: "Features",
    description: ["Cool stuff", "Random feature", "Team feature", "Developer stuff", "Another one"]
  },
  {
    title: "Resources",
    description: ["Resource", "Resource name", "Another resource", "Final resource"]
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"]
  }
]

interface CheckOutProps extends WithStyles<typeof styles> {}

const CheckOut = (props: CheckOutProps) => {
  const [stripe, setStripe] = React.useState(null)
  const [currentUser, setCurrentUser] = React.useState(null)
  const [signInOpen, setSignInOpen] = React.useState(false)

  const getCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (!user) throw new Error("not signed in")
      setCurrentUser(user)
      return user
    } catch (err) {
      return null
    }
  }

  React.useEffect(() => {
    //@ts-ignore
    setStripe(window.Stripe(process.env.STRIPE_PUBLIC_KEY))
    getCurrentUser()
  }, [])

  const getCurrentUrl = () => `${window.location.protocol}//${window.location.host}`

  async function redirectToCheckout() {
    const { error } = await stripe.redirectToCheckout({
      items: [{ sku: process.env.STRIPE_COURSE_SKU, quantity: 1 }],
      successUrl: `${getCurrentUrl()}/stripe-success`,
      cancelUrl: `${getCurrentUrl()}/stripe-cancel`,
      clientReferenceId: `${currentUser.attributes.sub}`,
      customerEmail: `${currentUser.attributes.email}`
    })
    if (error) {
      console.warn("Error:", error)
    }
  }
  const { classes } = props

  const purchaseClicked = async () => {
    try {
      const user = await getCurrentUser()
      if (!!user) {
        setSignInOpen(false)
        redirectToCheckout()
      } else {
        setSignInOpen(true)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={signInOpen}
        onClose={() => setSignInOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={signInOpen}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Please sign in / up before purchasing</h2>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.signInButtons}
              onClick={() => navigate(`/sign-in?source=/stripe-checkout`)}
            >
              Sign In
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.signInButtons}
              onClick={() => navigate(`/sign-up?source=/stripe-checkout`)}
            >
              Sign Up
            </Button>
            {/* <SignIn onSignedIn={purchaseClicked} /> */}
          </div>
        </Fade>
      </Modal>

      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          Master the ESP32
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          Build the next generation of IOT devices
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} direction="column" alignItems="center" justify="center">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === "Enterprise" ? 12 : 6} md={4}>
              <Card className={classes.card}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      <s>${tier.price}</s> $49
                    </Typography>
                  </div>
                  <Typography className={classes.starupSpecial} align="center" color="primary">
                    Startup Special
                  </Typography>
                  <ul>
                    {tier.description.map(line => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant="contained" color="primary" onClick={purchaseClicked}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      {/* <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map(footer => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map(item => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Container> */}
      {/* End footer */}
    </>
  )
}
export default withStyles(styles)(CheckOut)
